import React, { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faFlask } from "@fortawesome/free-solid-svg-icons";

interface MoreActivitiesProps {
    setPage: Dispatch<SetStateAction<string>>;
}

export const MoreActivitiesButton = ({ setPage }: MoreActivitiesProps) => {
    return (
        <div className="more-activities-button">
            <FontAwesomeIcon
                id="flask"
                className="fa-solid fa-3x"
                icon={faFlask}
            />
            <div className="caption">
                <h3>
                    <button
                        className="activity-button"
                        aria-label="Activities Page Link"
                    >
                        More Activities
                        <FontAwesomeIcon
                            className="fa-solid icon"
                            icon={faChevronRight}
                            aria-hidden="true"
                        />
                    </button>
                </h3>
                <p>Get to know Kasi.</p>
            </div>
        </div>
    );
};
