import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { activities } from "../utils/kasi";
import "./Interactives.css";

interface InteractivesProps {
    height: number;
}

export const Interactives = ({ height }: InteractivesProps) => {
    return (
        <section
            id="Interactives"
            className="Interactives"
            style={{ minHeight: `${height}px` }}
        >
            <h2 className="section-header">Activities</h2>
            <div className="interactives med-margin">
                {activities.map((activity, index) => {
                    return (
                        <div className="interactive" key={index}>
                            <img
                                src={activity.img}
                                alt={activity.title}
                                aria-hidden="true"
                            />
                            <div className="caption">
                                <h3>
                                    <a href="">
                                        {activity.title}
                                        <FontAwesomeIcon
                                            className="fa-solid icon"
                                            icon={faChevronRight}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </h3>
                                <p>{activity.description}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};
