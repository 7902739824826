import kasiByAlchemie from '../images/kasi-by-alchemie.png';
import email from '../images/email.png';
import { media } from '../utils/kasi';
import './Footer.css';

export const Footer = () => {
	return (
		<footer className='Footer'>
			<div className='footer-content'>
				<img
					className='footer-logo'
					src={kasiByAlchemie}
					alt='kasi by alchemie logo'
				/>
				<div className='media'>
					<div>
						<a href='#Welcome' aria-label='Alchemie Email link'>
							<img
								className='footer-icon'
								src={email}
								alt='Alchemie Email icon'
							/>
						</a>
					</div>
					<div className='media-icons'>
						{media.map((m, index) => {
							return (
								<a
									href={m.link}
									style={{ padding: '8px' }}
									aria-label={`${m.title} link`}
								>
									<img
										className='footer-icon'
										src={m.img}
										alt={`${m.title} icon`}
									/>
								</a>
							);
						})}
					</div>
				</div>
			</div>
		</footer>
	);
};
