import { Dispatch, SetStateAction } from 'react';
import kasiLogo from '../images/kasi-logo.png';
import './Header.css';

interface HeaderProps {
	setPage: Dispatch<SetStateAction<string>>;
}

export const Header = ({ setPage }: HeaderProps) => {
	return (
		<header className='Header'>
			<a href='#Welcome' onClick={() => setPage('Home')}>
				<img className='logo' src={kasiLogo} alt='kasi logo' />
			</a>
			<nav>
				<ul>
					<li className='home-page-link'>
						<a
							aria-label='Home Page Link'
							className='nav-link'
							href='#Welcome'
							onClick={() => setPage('Home')}
						>
							Home
						</a>
					</li>
					<li>
						<a
							aria-label='Start Page Link'
							className='nav-link'
							href='#Get-Started'
							onClick={() => setPage('GetStarted')}
						>
							Get Started
						</a>
					</li>
					<li>
						<a
							aria-label='Activities Page Link'
							className='nav-link'
							href='#Activities'
							onClick={() => setPage('Interactives')}
						>
							Activities
						</a>
					</li>
				</ul>
			</nav>
		</header>
	);
};
