import equation from "../images/LewisLRNicon.png";
import email from "../images/email.png";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import twitter from "../images/twitter.png";
import tiktok from "../images/tiktok.png";
import youtube from "../images/youtube.png";
import linkedin from "../images/linkedin.png";
import vimeo from "../images/vimeo.png";

export const activities = [
    {
        title: "Equations",
        img: equation,
        description: "General description of equations",
    },
    // {
    //   title: "Equations",
    //   img: equation,
    //   description: "General description of equations",
    // },
    // {
    //   title: "Equations",
    //   img: equation,
    //   description: "General description of equations",
    // },
    // {
    //   title: "Equations",
    //   img: equation,
    //   description: "General description of equations",
    // },
];

export const media = [
    {
        img: linkedin,
        title: "Alchemie LinkedIn",
        link: "https://www.linkedin.com/company/alchemie/",
    },
    {
        img: facebook,
        title: "Alchemie Facebook",
        link: "https://www.facebook.com/AlchemieSolutions/",
    },
    {
        img: twitter,
        title: "Alchemie Twitter",
        link: "https://twitter.com/learnalchemie?lang=en",
    },
    {
        img: instagram,
        title: "Alchemie Instagram",
        link: "https://www.instagram.com/learnalchemie/?hl=en",
    },
    {
        img: tiktok,
        title: "Alchemie TikTok",
        link: "https://www.tiktok.com/@learnalchemie?lang=en",
    },
    {
        img: youtube,
        title: "Alchemie Youtube",
        link: "https://www.youtube.com/channel/UCixNK3xkxq2X0Z9kcqTXDuQ",
    },
    { img: vimeo, title: "Alchemie Vimeo", link: "https://vimeo.com/alchemie" },
];
