import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import './Home.css';
import kasiIllustration from '../images/kasi-illustration.png';

interface HomeProps {
	height: number;
	setPage: Dispatch<SetStateAction<string>>;
}

export const Home = ({ height, setPage }: HomeProps) => {
	return (
		<section
			id='Home'
			className='Home'
			style={{ minHeight: `${height - 300}px` }}
		>
			<div className='two-sides content'>
				<div>
					<h2 className='text-left section-header'>Welcome to Kasi</h2>
					<p>
						Explore digital interactives with physical manipulatives that are
						recognized by Computer Visions (CV) and provide feedback using audio
						Augmented Reality (AR). <br />
						<br /> Start your learning journey with Kasi.
					</p>
					<div className='home-buttons'>
						<button
							onClick={() => setPage('GetStarted')}
							aria-label='Start Setup Page button'
						>
							Start Setup
						</button>
						<button
							onClick={() => setPage('Interactives')}
							aria-label='Interactive Activities Page button'
						>
							Browse Interactives
						</button>
					</div>
				</div>
				<img
					src={kasiIllustration}
					alt='Decorative image of hand placing Kasi pieces'
					className='welcome-illustration landing-right'
					style={{ height: '400px' }}
				/>
			</div>
		</section>
	);
};
