import React from 'react';
import {Kasi} from "./Kasi/Kasi";

function App() {
    return (
        <Kasi/>
    )
}

export default App;
