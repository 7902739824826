import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { activities } from "../utils/kasi";
import { MoreActivitiesButton } from "./MoreActivitiesButton";
import "./Activities.css";

interface ActivitiesProps {
    height: number;
    setPage: Dispatch<SetStateAction<string>>;
}

export const Activities = ({ height, setPage }: ActivitiesProps) => {
    return (
        <section
            id="Activities"
            className="Activities"
            style={{ minHeight: `${(height / 3) * 2 - 200}px` }}
        >
            <div className="content">
                <h5 className="section-header">Browse Activities</h5>
                <div className="activities med-margin">
                    {activities.map((activity, index) => {
                        if (index < 2) {
                            return (
                                <div className="activity" key={index}>
                                    <img
                                        src={activity.img}
                                        // alt={`Decorative image of the ${activity.title} activity`}
                                        aria-hidden="true"
                                    />
                                    <div className="caption">
                                        <h3>
                                            <a
                                                href=""
                                                aria-label={
                                                    activity.title + " link"
                                                }
                                            >
                                                {activity.title}
                                                <FontAwesomeIcon
                                                    className="fa-solid icon"
                                                    icon={faChevronRight}
                                                    aria-hidden="true"
                                                />
                                            </a>
                                        </h3>
                                        <p>{activity.description}</p>
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <MoreActivitiesButton setPage={setPage} />
                </div>
            </div>
        </section>
    );
};
