import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { Home } from "./Home";
import { GetStarted } from "./GetStarted";
import { Activities } from "./Activities";
import { About } from "./About";
import { Interactives } from "./Interactives";
import orangeBackground from "../images/orange-background.svg";
import bgSmall from "../images/bg-small.svg";
import bgBig from "../images/bg-big.svg";

interface TabContentProps {
    page: string;
    setPage: Dispatch<SetStateAction<string>>;
}

export const TabContent = ({ page, setPage }: TabContentProps) => {
    const [windowHeight, setWindowHeight] = useState(0);

    useLayoutEffect(() => {
        function updateSize() {
            setWindowHeight(window.innerHeight);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    if (page === "Home") {
        return (
            <div id="TabContent">
                <Home height={windowHeight} setPage={setPage} />
                <img
                    src={bgSmall}
                    className="background"
                    // style={{ height: `${windowHeight}px` }}
                ></img>
                <About height={windowHeight} />
                <Activities height={windowHeight} setPage={setPage} />
            </div>
        );
    } else if (page === "GetStarted") {
        return (
            <div id="TabContent">
                <GetStarted height={windowHeight} />
            </div>
        );
    }
    return (
        <div id="TabContent">
            <Interactives height={windowHeight} />
        </div>
    );
};
