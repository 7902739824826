import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

interface GetStartedProps {
    height: number;
}

export const GetStarted = ({ height }: GetStartedProps) => {
    const [videoSize, setVideoSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            let innerWidth = window.innerWidth;
            if (innerWidth > 750) innerWidth = 750;
            innerWidth = innerWidth * 0.9;
            setVideoSize([innerWidth, innerWidth * 0.6]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return (
        <section
            id="GetStarted"
            className="GetStarted"
            style={{
                minHeight: `${height}px`,
            }}
        >
            <div className="content">
                <h2 className="section-header">Get Started</h2>
                <iframe
                    title="Getting started with Kasi"
                    className="video"
                    height={videoSize[1]}
                    width={videoSize[0]}
                    src="https://youtube.com/embed/K-3A9VP5CRw"
                ></iframe>
            </div>
        </section>
    );
};
