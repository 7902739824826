import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import kasiLogo from "./images/kasi-logo.png";
import kasiByAlchemie from "./images/kasi-by-alchemie.png";
import email from "./images/email.png";
import "./Kasi.css";
import { activities, media } from "./utils/kasi";
import { Home } from "./Components/Home";
import { GetStarted } from "./Components/GetStarted";
import { Activities } from "./Components/Activities";
import { About } from "./Components/About";
import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer";
import { TabContent } from "./Components/TabContent";

export const Kasi = () => {
    const [page, setPage] = useState("Home");

    return (
        <div id="Kasi" className="Kasi">
            <div id="Welcome"></div>
            <Header setPage={setPage} />
            <main>
                <TabContent page={page} setPage={setPage} />
            </main>
            <Footer />
        </div>
    );
};
