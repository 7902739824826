import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyDZByKGG7Q0c1cTw2dME7aco0wFeU4mwwE',
	authDomain: 'kasi-system.firebaseapp.com',
	databaseURL: 'https://kasi-system-default-rtdb.firebaseio.com',
	projectId: 'kasi-system',
	storageBucket: 'kasi-system.appspot.com',
	messagingSenderId: '566001440944',
	appId: '1:566001440944:web:ef9a1f028ba4f2e5fe4375',
	measurementId: 'G-5Z7K7C1JH1',
};
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
